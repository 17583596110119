@use "mixin";

.footer {
    width: 100%;
    height: auto;

    @include mixin.mq(sp) {
        overflow-x: hidden;
    }

    .footer-container {
        position: relative;

        @include mixin.mq(sp) {
            width: 100%;
            height: 400px;
            overflow: hidden;
        }

        .footer-video {
            width: 100%;
            height: auto;

            @include mixin.mq(sp) {
                width: auto;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }

        .footer-contents {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: end;
            width: 100%;
            height: auto;
            padding: 6em;

            @include mixin.mq(bg-tab) {
                padding: 0 3em;
            }

            @include mixin.mq(sp) {
                padding: 0 1.5em;
            }

            .footer-left {
                margin-top: 3em;

                @include mixin.mq(bg-tab) {
                    margin-top: 4em;   
                }

                @include mixin.mq(sp) {
                    margin-top: 3em;
                }

                .footer-left-logo-inner {
                    text-decoration: none;
                    cursor: pointer;

                    .footer-left-logo {
                        @include mixin.mainFont;
                        color: #fff;
                        font-size: 4.5em;

                        @include mixin.mq(bg-tab) {
                            font-size: 3em;
                        }

                        @include mixin.mq(sm-tab) {
                            font-size: 2em;
                        }

                        @include mixin.mq(sp) {
                            font-size: 1.6em;
                        }

                        @include mixin.mq(bg-pc) {
                            font-size: 6em;
                        }

                        .footer-left-back-text {
                            position: relative;
                        }

                        .footer-c::before {
                            content: 'C';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-r::before {
                            content: 'R';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-e::before {
                            content: 'E';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-a::before {
                            content: 'A';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-t::before {
                            content: 'T';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-i::before {
                            content: 'I';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-v::before {
                            content: 'V';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-second-e::before {
                            content: 'E';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-l::before {
                            content: 'L';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-second-a::before {
                            content: 'A';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-b::before {
                            content: 'B';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }

                        .footer-o::before {
                            content: 'O';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            font-size: 1.2em;
                            color: transparent;
                            -webkit-text-stroke: 1px #fff;

                            @include mixin.mq(sm-tab) {
                                top: -6px;
                            }

                            @include mixin.mq(sp) {
                                left: 2px;
                            }
                        }
                    }
                }

                .footer-left-lists {
                    list-style: none;
                    margin: 4em auto 0;

                    @include mixin.mq(bg-tab) {
                        margin: 3em auto 0;
                    }

                    @include mixin.mq(sm-tab) {
                        margin: 2em auto 0;
                    }

                    @include mixin.mq(sp) {
                        margin: 2em auto 0;
                    }

                    @include mixin.mq(bg-pc) {
                        margin: 6em auto 0;
                    }

                    .footer-left-list {
                        margin: 2em auto;

                        @include mixin.mq(bg-tab) {
                            margin: 1.6em auto;
                        }

                        @include mixin.mq(sm-tab) {
                            margin: 1em auto;
                        }

                        @include mixin.mq(sp) {
                            margin: .8em auto;
                        }

                        @include mixin.mq(bg-pc) {
                            margin: 3em auto;
                        }

                        .footer-left-list-link {
                            @include mixin.mainFont;
                            color: #fff;
                            text-decoration: none;
                            font-size: 2em;
                            letter-spacing: 4px;
                            cursor: pointer;
                            transition: all .5s;

                            &:hover {
                                opacity: .7;
                            }

                            @include mixin.mq(bg-tab) {
                                font-size: 1.2em;
                            }

                            @include mixin.mq(sm-tab) {
                                font-size: 1em;
                            }

                            @include mixin.mq(sp) {
                                font-size: 1em;
                            }

                            @include mixin.mq(bg-pc) {
                                font-size: 3em;
                            }
                        }
                    }
                }
            }

            .footer-right {

                .footer-right-logo-inner {
                    width: 100%;
                    height: auto;

                    @include mixin.mq(bg-tab) {
                        width: 80%;
                        margin-left: auto;
                    }

                    @include mixin.mq(sm-tab) {
                        width: 60%;
                    }

                    .footer-right-logo-link {
                        text-decoration: none;

                        .footer-right-logo {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

