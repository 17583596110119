.footer {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 420px) {
  .footer {
    overflow-x: hidden;
  }
}
.footer .footer-container {
  position: relative;
}
@media screen and (max-width: 420px) {
  .footer .footer-container {
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
}
.footer .footer-container .footer-video {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-video {
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
.footer .footer-container .footer-contents {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: auto;
  padding: 6em;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents {
    padding: 0 3em;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents {
    padding: 0 1.5em;
  }
}
.footer .footer-container .footer-contents .footer-left {
  margin-top: 3em;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-left {
    margin-top: 4em;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left {
    margin-top: 3em;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner {
  text-decoration: none;
  cursor: pointer;
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #fff;
  font-size: 4.5em;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo {
    font-size: 3em;
  }
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo {
    font-size: 2em;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo {
    font-size: 1.6em;
  }
}
@media screen and (min-width: 2200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo {
    font-size: 6em;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-left-back-text {
  position: relative;
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-c::before {
  content: "C";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-c::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-c::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-r::before {
  content: "R";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-r::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-r::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-e::before {
  content: "E";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-e::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-e::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-a::before {
  content: "A";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-a::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-a::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-t::before {
  content: "T";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-t::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-t::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-i::before {
  content: "I";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-i::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-i::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-v::before {
  content: "V";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-v::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-v::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-e::before {
  content: "E";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-e::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-e::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-l::before {
  content: "L";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-l::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-l::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-a::before {
  content: "A";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-a::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-second-a::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-b::before {
  content: "B";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-b::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-b::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-o::before {
  content: "O";
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 1.2em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-o::before {
    top: -6px;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-logo-inner .footer-left-logo .footer-o::before {
    left: 2px;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-lists {
  list-style: none;
  margin: 4em auto 0;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists {
    margin: 3em auto 0;
  }
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists {
    margin: 2em auto 0;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists {
    margin: 2em auto 0;
  }
}
@media screen and (min-width: 2200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists {
    margin: 6em auto 0;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list {
  margin: 2em auto;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list {
    margin: 1.6em auto;
  }
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list {
    margin: 1em auto;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list {
    margin: 0.8em auto;
  }
}
@media screen and (min-width: 2200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list {
    margin: 3em auto;
  }
}
.footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #fff;
  text-decoration: none;
  font-size: 2em;
  letter-spacing: 4px;
  cursor: pointer;
  transition: all 0.5s;
}
.footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link:hover {
  opacity: 0.7;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link {
    font-size: 1em;
  }
}
@media screen and (max-width: 420px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link {
    font-size: 1em;
  }
}
@media screen and (min-width: 2200px) {
  .footer .footer-container .footer-contents .footer-left .footer-left-lists .footer-left-list .footer-left-list-link {
    font-size: 3em;
  }
}
.footer .footer-container .footer-contents .footer-right .footer-right-logo-inner {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .footer .footer-container .footer-contents .footer-right .footer-right-logo-inner {
    width: 80%;
    margin-left: auto;
  }
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-contents .footer-right .footer-right-logo-inner {
    width: 60%;
  }
}
.footer .footer-container .footer-contents .footer-right .footer-right-logo-inner .footer-right-logo-link {
  text-decoration: none;
}
.footer .footer-container .footer-contents .footer-right .footer-right-logo-inner .footer-right-logo-link .footer-right-logo {
  width: 100%;
  height: auto;
}